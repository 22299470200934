import { useStaticQuery, graphql } from 'gatsby';

export const useSocialLinks = () => {
  const { socialLinks } = useStaticQuery(graphql`
    query SiteSocialLinks {
      socialLinks: siteConfigJson(id: {eq: "Social share info"}) {
        twitter
        instagram
        facebook
      }
    }
  `)

  return {
    facebook: {
      username: socialLinks.facebook,
      link: `https://facebook.com/${socialLinks.facebook}`,
    },
    twitter: {
      username: `@${socialLinks.twitter}`.replace('@@', '@'),
      link: `https://twitter.com/${socialLinks.twitter.replace('@', '')}`
    },
    instagram: {
      username: socialLinks.instagram,
      link: `https://instagram.com/${socialLinks.instagram}`
    }
  }
}
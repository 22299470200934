/** @jsx jsx */

import { jsx, Heading } from 'theme-ui';
import { ContentBlock, HeaderSpacing, MapImageGlobal } from "../../../components/page"
import { Trans } from 'react-i18next';
import { useSocialLinks } from '../../../data/site';
import { Fragment } from "react";

export default () => {
  const social = useSocialLinks()

  return (
    <Fragment>
      <MapImageGlobal />
      <HeaderSpacing />
      <ContentBlock>
        <HeaderSpacing />
        <Heading variant='headings.1'>
          <Trans>Welcome to the Progressive International.</Trans>
        </Heading>
      </ContentBlock>
    </Fragment>
  )
}